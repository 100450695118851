import React, { useState, useEffect } from "react"; // Added useEffect import
import FeedbackForm from "../components/FeedbackForm";
import Library from "../components/Library";
import GeneratePodcast from "../components/GeneratePodcast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLibrary } from "../context/LibraryContext";

const PodFastForm = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("generate");

  //const [libraryItems, setLibraryItems] = useState([]);
  const [refreshLibrary, setRefreshLibrary] = useState(0);
  const { fetchLibraryItems } = useLibrary();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/auth");
    }
  }, [navigate]);

  // Save activeTab to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("podcastActiveTab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    fetchLibraryItems();
  }, []);

  /*const fetchLibrary = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("http://localhost:8000/api/library", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLibraryItems(response.data);
    } catch (error) {
      console.error("Error fetching library:", error);
    }
  };*/

  // Single definition of handlePodcastGenerated
  const handlePodcastGenerated = () => {
    setRefreshLibrary((prev) => prev + 1);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "generate":
        return <GeneratePodcast onPodcastGenerated={handlePodcastGenerated} />;
      case "library":
        return <Library />;
      case "feedback":
        return <FeedbackForm />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header page="get-started" />
      <div className="hidden md:block text-center">
        <span className="underline text-lg font-medium">
          Early Access Beta - Experience It First 🚀
        </span>
      </div>
      <main className="flex-grow flex justify-center">
        <div className="w-full max-w-7xl">
          <div className="container mx-auto px-4 py-8">
            <div className="flex mb-4 justify-center">
              <button
                className={`w-40 mr-2 px-4 py-2 rounded ${
                  activeTab === "generate"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setActiveTab("generate")}
              >
                Generate Podcast
              </button>
              <button
                className={`w-40 mr-2 px-4 py-2 rounded ${
                  activeTab === "library"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setActiveTab("library")}
              >
                Library
              </button>
              <button
                className={`w-40 px-4 py-2 rounded ${
                  activeTab === "feedback"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setActiveTab("feedback")}
              >
                Feedback
              </button>
            </div>
            <div className="mt-4">{renderTabContent()}</div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PodFastForm;
