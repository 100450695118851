import React, { useState, useEffect } from "react";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./AudioPlayer.css";
import { useNavigate } from "react-router-dom";
import { useLibrary } from "../context/LibraryContext";
import ReactMarkdown from "react-markdown";

// Helper functions
const GENERATION_STATE_KEY = "podcastGenerationState";

const saveGenerationState = (state, userId) => {
  const stateToSave = {
    ...state,
    displayTopic: state.displayTopic,
    result: state.result,
    audioUrl: state.audioUrl,
    status: state.status,
  };
  localStorage.setItem(
    `${GENERATION_STATE_KEY}_${userId}`,
    JSON.stringify(stateToSave),
  );
};

const loadGenerationState = (userId) => {
  const saved = localStorage.getItem(`${GENERATION_STATE_KEY}_${userId}`);
  return saved ? JSON.parse(saved) : null;
};

const Alert = ({ children, variant = "error" }) => {
  const bgColor = variant === "error" ? "bg-red-100" : "bg-blue-100";
  const textColor = variant === "error" ? "text-red-800" : "text-blue-800";

  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-lg mb-4`}>
      {children}
    </div>
  );
};

// New podcast summary component that handles both summary and topic summaries
const PodcastSummary = ({ summary, topicSummaries }) => {
  const [expandedTopics, setExpandedTopics] = useState({});
  const [showAllTopics, setShowAllTopics] = useState(false);

  // Check if we have valid topic summaries
  const hasTopicSummaries =
    Array.isArray(topicSummaries) && topicSummaries.length > 0;

  // Toggle a specific topic's expanded state
  const toggleTopic = (topicId) => {
    setExpandedTopics((prev) => ({
      ...prev,
      [topicId]: !prev[topicId],
    }));
  };

  // Toggle all topics
  const toggleAllTopics = () => {
    if (showAllTopics) {
      // Collapse all
      setExpandedTopics({});
    } else {
      // Expand all
      const allExpanded = {};
      topicSummaries.forEach((topic, index) => {
        allExpanded[index] = true;
      });
      setExpandedTopics(allExpanded);
    }
    setShowAllTopics(!showAllTopics);
  };

  // Main summary section - shown regardless of topic summaries
  const MainSummarySection = () => {
    if (!summary) {
      return (
        <p className="text-gray-500 italic h-12 flex items-center justify-center">
          No summary available.
        </p>
      );
    }

    // If we have topic summaries, extract just the main part
    let mainSummaryText = summary;
    if (hasTopicSummaries) {
      const topicSectionIndex = summary.indexOf("## Topic Summaries");
      if (topicSectionIndex !== -1) {
        mainSummaryText = summary.substring(0, topicSectionIndex).trim();
      }
    }

    return (
      <ReactMarkdown
        className="markdown-content"
        components={{
          h1: ({ node, ...props }) => (
            <h1 className="text-xl font-bold mt-4 mb-2" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="text-lg font-bold mt-4 mb-2" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-md font-bold mt-3 mb-1" {...props} />
          ),
          p: ({ node, ...props }) => <p className="mb-3 text-sm" {...props} />,
          ul: ({ node, ...props }) => (
            <ul className="list-disc pl-5 mb-3" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="list-decimal pl-5 mb-3" {...props} />
          ),
          li: ({ node, ...props }) => <li className="mb-1" {...props} />,
        }}
      >
        {mainSummaryText}
      </ReactMarkdown>
    );
  };

  // Topic summaries section
  const TopicSummariesSection = () => {
    if (!hasTopicSummaries) return null;

    return (
      <div className="mt-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-semibold">Topic Summaries</h3>
          <button
            onClick={toggleAllTopics}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            {showAllTopics ? "Collapse All" : "Expand All"}
          </button>
        </div>

        <div className="space-y-2">
          {topicSummaries.map((topic, index) => (
            <div key={index} className="border rounded-lg overflow-hidden">
              <button
                onClick={() => toggleTopic(index)}
                className="w-full p-2 text-left font-medium bg-gray-50 hover:bg-gray-100 transition-colors duration-200 flex justify-between items-center"
              >
                <span>{topic.topic || `Topic ${index + 1}`}</span>
                <svg
                  className={`w-5 h-5 transform transition-transform duration-200 ${
                    expandedTopics[index] ? "rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {expandedTopics[index] && (
                <div className="p-2 bg-gray-100">
                  <ReactMarkdown
                    className="markdown-content"
                    components={{
                      p: ({ node, ...props }) => (
                        <p className="mb-3 text-sm" {...props} />
                      ),
                    }}
                  >
                    {topic.summary}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 bg-white">
      <MainSummarySection />
      <TopicSummariesSection />
    </div>
  );
};

const CustomAudioPlayer = ({ src, onError }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [audioBlob, setAudioBlob] = useState(null);

  // Format audio URL and add authentication
  const fetchAudio = async (url) => {
    try {
      const token = localStorage.getItem("token");
      if (!url || !token) {
        throw new Error("Missing URL or authentication token");
      }

      const fullUrl = url.startsWith("http")
        ? url
        : `${process.env.REACT_APP_API_URL || ""}${url}`;

      const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      setAudioBlob(audioUrl);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching audio:", error);
      onError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (src) {
      setIsLoading(true);
      fetchAudio(src);
    }

    // Cleanup
    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [src]);

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  return (
    <div className="audio-player-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">Loading audio...</div>
        </div>
      )}
      {audioBlob && (
        <AudioPlayer
          src={audioBlob}
          onLoadedData={handleLoadedData}
          onError={(e) => {
            console.error("Audio playback error:", {
              error: e,
              originalSrc: src,
            });
            setIsLoading(false);
            onError(e);
          }}
          autoPlay={false}
          showJumpControls={true}
          showSkipControls={false}
          showFilledVolume={true}
          customProgressBarSection={[
            "CURRENT_TIME",
            "PROGRESS_BAR",
            "DURATION",
          ]}
          customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
          className={`rounded-lg custom-player ${isLoading ? "loading" : ""}`}
          style={{
            borderRadius: "0.5rem",
            backgroundColor: "white",
            boxShadow: "none",
          }}
          customStyles={{
            progressBar: {
              backgroundColor: "#E5E7EB",
            },
            progressBarHeight: 2,
            trackArtistColor: "#4B5563",
            currentTimeColor: "#4B5563",
            durationColor: "#4B5563",
          }}
        />
      )}
    </div>
  );
};

// Add CSS for loading overlay
const styles = `
.audio-player-container {
  position: relative;
  width: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  border-radius: 0.5rem;
}

.loading-spinner {
  color: #4B5563;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.loading-spinner::before {
  content: '';
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 2px solid #E5E7EB;
  border-top-color: #4B5563;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.custom-player.loading {
  opacity: 0.7;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  animation: gradientMove 2s linear infinite;
}
`;

// Add style tag to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

// Add this component definition after the CustomAudioPlayer component
const LanguageSelector = ({ 
  language, 
  languageSearch, 
  setLanguageSearch, 
  isLanguageDropdownOpen, 
  toggleLanguageDropdown, 
  filteredLanguages, 
  handleLanguageSelect 
}) => {
  return (
    <div className="relative language-selector">
      <div className="relative">
        <input
          type="text"
          className="w-full p-2 border rounded-md"
          placeholder="Select a language..."
          value={language || "English"}
          readOnly
          onClick={toggleLanguageDropdown}
        />
        <svg
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 transition-transform duration-200 ${
            isLanguageDropdownOpen ? "rotate-180" : ""
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {isLanguageDropdownOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md max-h-60 overflow-y-auto">
          <input
            type="text"
            className="w-full p-2 border-b"
            placeholder="Search languages..."
            value={languageSearch}
            onChange={(e) => setLanguageSearch(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            autoFocus
          />
          {filteredLanguages.length > 0 ? (
            filteredLanguages.map((lang) => (
              <div
                key={lang}
                className={`p-2 cursor-pointer hover:bg-gray-100 ${
                  language === lang ? "bg-gray-200" : ""
                }`}
                onClick={() => handleLanguageSelect(lang)}
              >
                {lang}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500">No languages found</div>
          )}
        </div>
      )}
    </div>
  );
};

const GeneratePodcast = ({ onPodcastGenerated }) => {
  const [topic, setTopic] = useState("");
  const [displayTopic, setDisplayTopic] = useState("");
  const [duration, setDuration] = useState("");
  const [language, setLanguage] = useState("English");
  const [dialect, setDialect] = useState("Australian");
  const [characterVibe, setCharacterVibe] = useState("Podcast host");
  const [voiceType, setVoiceType] = useState("any");
  const [languageSearch, setLanguageSearch] = useState("");
  const [characterStyle, setCharacterStyle] = useState("");
  const [customCharacterStyle, setCustomCharacterStyle] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [status, setStatus] = useState("initial");
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [podCastError, setPodCastError] = useState("");
  const [activeTab, setActiveTab] = useState("transcript");
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const { libraryItems, isLoading, fetchLibraryItems } = useLibrary();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  // Add supported languages array
  const supportedLanguages = [
    "Afrikaans", "Arabic", "Armenian", "Azerbaijani", "Belarusian", "Bosnian", 
    "Bulgarian", "Catalan", "Chinese", "Croatian", "Czech", "Danish", "Dutch", 
    "English", "Estonian", "Finnish", "French", "Galician", "German", "Greek", 
    "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", 
    "Kannada", "Kazakh", "Korean", "Latvian", "Lithuanian", "Macedonian", "Malay", 
    "Marathi", "Maori", "Nepali", "Norwegian", "Persian", "Polish", "Portuguese", 
    "Romanian", "Russian", "Serbian", "Slovak", "Slovenian", "Spanish", "Swahili", 
    "Swedish", "Tagalog", "Tamil", "Thai", "Turkish", "Ukrainian", "Urdu", 
    "Vietnamese", "Welsh"
  ];

  // Add filtered languages logic
  const filteredLanguages = languageSearch
    ? supportedLanguages.filter(lang => 
        lang.toLowerCase().includes(languageSearch.toLowerCase()))
    : supportedLanguages;

  const voiceTypeOptions = ["any", "masculine", "feminine"];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/auth");
    } else {
      // Decode the token to get the user ID
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      console.log("decodedToken", decodedToken);
      setUserId(decodedToken.user_id);

      // Load user-specific state
      const savedState = loadGenerationState(decodedToken.user_id);
      if (savedState) {
        setStatus(savedState.status);
        setProgress(savedState.progress || 0);
        setCurrentStep(savedState.currentStep || "");
        setSessionId(savedState.sessionId);
        setDisplayTopic(savedState.displayTopic);
        setResult(savedState.result);
        setAudioUrl(savedState.audioUrl);

        if (savedState.status === "processing") {
          pollProgress(savedState.sessionId);
        }
      } else {
        // Set default language to English
        setLanguage("English");
        
        // Fetch the last generated podcast for this user
        if (decodedToken.user_id) {
          fetchLastGeneratedPodcast(decodedToken.user_id);
        }
      }
    }
  }, [navigate]);

  const fetchLastGeneratedPodcast = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `/api/last-generated-podcast/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const lastPodcast = response.data;
      if (lastPodcast) {
        setStatus("completed");
        setResult(lastPodcast);
        setDisplayTopic(lastPodcast.topic);
        if (lastPodcast.audio_output?.url) {
          setAudioUrl(`{lastPodcast.audio_output.url}`);
        }
      }
    } catch (error) {
      console.error("Error fetching last generated podcast:", error);
    }
  };

  // Handle topic input with character limit
  const handleTopicChange = (e) => {
    const newTopic = e.target.value;
    setTopic(newTopic);
    // Update display topic with truncated version if necessary
    setDisplayTopic(
      newTopic.length > 50 ? `${newTopic.substring(0, 47)}...` : newTopic,
    );
  };

  // Update pollProgress to use user-specific storage
  const pollProgress = async (sid) => {
    try {
      const response = await fetch(`/api/progress/${sid}`);
      const data = await response.json();

      if (data.status === "completed") {
        console.log("Podcast generation completed:", data);
        fetchLibraryItems();
        setStatus("completed");
        setResult(data.result);

        if (data.result?.error) {
          setPodCastError(data.result?.error);
        }

        // Process audio URL
        if (data.result?.audio_output?.url) {
          // Remove template literals from the URL
          const processedUrl = data.result.audio_output.url.replace(
            /[{}]/g,
            "",
          );
          setAudioUrl(processedUrl);

          saveGenerationState(
            {
              status: "completed",
              result: data.result,
              audioUrl: processedUrl,
              displayTopic,
            },
            userId,
          );

          sessionStorage.setItem("lastDisplayTopic", displayTopic);

          // Ensure the library is updated
          if (onPodcastGenerated) {
            setTimeout(() => {
              onPodcastGenerated();
            }, 1000);
          }
        }
      } else if (data.status === "failed") {
        setStatus("failed");
        setError(data.error);
        localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
      } else if (data.status === "cancelled") {
        // User cancelled, reset to initial state
        setStatus("initial");
        setProgress(0);
        setCurrentStep("");
        setStatusMessage("");
        localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
        console.log("Podcast generation was cancelled by user");
      } else {
        // Enhanced progress tracking with validation
        // Ensure progress is a number between 0 and 1
        let progressValue = parseFloat(data.progress);
        if (isNaN(progressValue)) progressValue = 0;
        progressValue = Math.max(0, Math.min(1, progressValue));

        // Ensure we never go backwards in progress
        if (progressValue < progress) {
          progressValue = progress;
        }

        // Add slight progress increment even if backend reports same step
        // to give user feedback that processing is continuing
        if (progressValue === progress && progress < 0.99) {
          progressValue = progress;
        }

        setProgress(progressValue);
        setCurrentStep(data.current_step || "");
        setStatusMessage(data.message || "");

        console.log(
          `Progress update: ${data.current_step} - ${progressValue.toFixed(2)} - ${data.message}`,
        );

        saveGenerationState(
          {
            status: "processing",
            progress: progressValue,
            currentStep: data.current_step || "",
            sessionId: sid,
            displayTopic,
            message: data.message || "",
          },
          userId,
        );

        // Use dynamic polling interval based on the current step
        // More frequent polling during critical steps
        let pollInterval = 1000; // Default 1 second
        if (
          data.current_step === "podcast_audio_generator" ||
          data.current_step === "generate_audio"
        ) {
          pollInterval = 2000; // Audio generation can take longer, poll less frequently
        }

        // Only continue polling if not in cancelling state
        if (status !== "cancelling") {
          setTimeout(() => pollProgress(sid), pollInterval);
        }
      }
    } catch (err) {
      console.error("Poll progress error:", err);
      setError(err.message);
      setStatus("failed");
      localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
    }
  };

  // Add new handlers
  const handleDialectChange = (e) => {
    setDialect(e.target.value);
  };

  const handleCharacterVibeChange = (e) => {
    setCharacterVibe(e.target.value);
  };

  const handleVoiceTypeChange = (e) => {
    setVoiceType(e.target.value);
  };

  // Update handleSubmit to remove timeframe field
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setStatus("processing");
    console.log("Form submitted with:", {
      topic,
      duration,
      language,
      dialect,
      characterVibe,
      voiceType
    });

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/generate-podcast",
        {
          query: topic,
          // category and timeframe will use defaults from backend
          desired_length: duration,
          language: language,
          dialect: dialect,
          character_style: characterVibe,
          voice_type: voiceType
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const sid = response.data.session_id;
      setSessionId(sid);

      saveGenerationState(
        {
          status: "processing",
          progress: 0,
          currentStep: "",
          sessionId: sid,
          displayTopic,
        },
        userId
      );

      pollProgress(sid);
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      if (error.response?.status === 429) {
        setError(
          "You have reached the limit of 5 podcast generations per day. Please try again tomorrow.",
        );
      } else {
        setError(
          error.response?.data?.detail ||
            "An error occurred while initiating the podcast.",
        );
      }
      setStatus("initial");
      localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
    }
  };

  const handleAudioError = (error) => {
    console.error("Audio playback error:", error);
    setError("Error playing audio. Please try again.");
  };

  // Update handleNewPodcast to remove timeframe state reset
  const handleNewPodcast = () => {
    localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
    setTopic("");
    setDisplayTopic("");
    setDuration("");
    setLanguage("English");
    setDialect("Australian");
    setCharacterVibe("Podcast host");
    setVoiceType("any");
    setSessionId(null);
    setStatus("initial");
    setProgress(0);
    setCurrentStep("");
    setStatusMessage("");
    setAudioUrl(null);
    setResult(null);
    setError(null);
    setActiveTab("transcript");
    setCustomCharacterStyle("");
  };

  // Add new handlers for character style
  const handleCharacterStyleChange = (e) => {
    const selectedStyle = e.target.value;
    if (selectedStyle !== "Other") {
      setCharacterStyle(selectedStyle);
      setCustomCharacterStyle("");
    } else {
      setCharacterStyle("");
    }
  };

  const handleCustomCharacterStyleChange = (e) => {
    setCustomCharacterStyle(e.target.value);
    setCharacterStyle(e.target.value);
  };

  const renderReferences = (referenceText) => {
    if (!referenceText) return null;
    try {
      // Try to parse as JSON first
      const lines = JSON.parse(referenceText.replace(/'/g, '"'));
      console.log("lines", lines);

      return (
        <div className="max-h-96">
          {Object.entries(lines).map(([key, value]) => {
            return (
              <div
                key={key}
                className="mb-3 last:mb-0 pl-[6rem] relative bg-white p-2 border-b last:border-b-0"
              >
                <span className="absolute left-2 top-2 font-bold">{key}.</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<a href="${value}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline break-all">${value}</a>`,
                  }}
                  className="text-sm reference-line ml-4"
                />
              </div>
            );
          })}
        </div>
      );
    } catch (e) {
      // Fallback to text format
      console.error("Error parsing references:", e);
      const lines = referenceText.split("\n").filter((line) => line.trim());

      return (
        <div className="max-h-96">
          {lines.map((line, index) => (
            <div
              key={index}
              className="mb-3 last:mb-0 pl-4 bg-white p-2 border-b last:border-b-0"
            >
              <div className="text-sm reference-line">{line}</div>
            </div>
          ))}
        </div>
      );
    }
  };

  // Update getDisplayStepName with new nodes
  const getDisplayStepName = (step) => {
    const stepNameMap = {
      prompt_engineer: "Understanding Your Query",
      search_execution: "Searching for information",
      markdown_agent: "Analyzing Information",
      summary_agent: "Brainstorming",
      podcast_script_generator: "Writing Content",
      podcast_character_gen: "Applying Character Style",
      podcast_vibe: "Creating Voice Style",
      podcast_audio_generator: "Giving it a Voice",
      reflection: "Thinking and analyzing",
      summarization: "Gathering information",
      summary_reflection: "Thinking and organizing",
      generate_podcast_script: "Putting Together",
      generate_audio: "Generating Podcast",
    };

    return stepNameMap[step] || step;
  };

  // Add this new component for title display
  const TopicDisplay = ({ topic }) => (
    <div className="w-full bg-gray-100 p-4 mb-4 rounded-lg">
      <h3 className="text-lg font-medium text-gray-900 truncate">{topic}</h3>
    </div>
  );

  // Add new functions for language dropdown handling
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen((prev) => !prev);
  };

  const handleLanguageSelect = (lang) => {
    setLanguage(lang);
    setLanguageSearch("");
    setIsLanguageDropdownOpen(false);
  };

  // Add new useEffect for handling clicks outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isLanguageDropdownOpen &&
        event.target.closest(".language-selector") === null
      ) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isLanguageDropdownOpen]);

  // Add this function to the GeneratePodcast component
  const handleCancelGeneration = async () => {
    if (!sessionId) return;
    
    // Add confirmation dialog
    if (!window.confirm("Are you sure you want to cancel this podcast generation? This action cannot be undone.")) {
      return;
    }
    
    try {
      setStatus("cancelling"); // Add a new intermediate state
      
      const token = localStorage.getItem("token");
      await axios.post(
        `/api/cancel-podcast/${sessionId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      // Reset state
      setStatus("initial");
      setProgress(0);
      setCurrentStep("");
      setStatusMessage("");
      setSessionId(null);
      
      // Clear generation state from localStorage
      localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
      
    } catch (error) {
      console.error("Error cancelling podcast:", error);
      setError(error.response?.data?.detail || "Failed to cancel podcast generation");
      setStatus("processing"); // Go back to processing if cancel fails
    }
  };

  const renderContent = () => {
    switch (status) {
      case "initial":
        return (
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Topic Input - Full width with label */}
            <div className="relative">
              <label htmlFor="podcast-topic" className="block text-sm font-medium text-gray-700 mb-1">
                Podcast Topic
              </label>
              <textarea
                id="podcast-topic"
                className="w-full p-3 border rounded-md resize-none min-h-[120px]"
                value={topic}
                onChange={handleTopicChange}
                placeholder="Better inputs will lead to better podcasts."
                maxLength={500}
                rows="4"
              ></textarea>
              <div className="text-right text-sm text-gray-500">
                {500 - topic.length} characters remaining
                {topic.length > 50 && (
                  <div className="text-yellow-600">
                    Note: Title will be truncated to 50 characters
                  </div>
                )}
              </div>
            </div>

            {/* Layout for Duration, Language, Dialect, etc. */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Duration field - now takes full width on small screens, first column on medium+ */}
              <div className="relative md:col-span-1">
                <label htmlFor="podcast-duration" className="block text-sm font-medium text-gray-700 mb-1">
                  Duration
                </label>
                <select
                  id="podcast-duration"
                  className="w-full p-2 border rounded-md"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  required
                >
                  <option value="">Select a duration</option>
                  <option value="short">Short</option>
                  <option value="long">Long</option>
                </select>
              </div>

              {/* Language field */}
              <div className="relative">
                <label htmlFor="podcast-language" className="block text-sm font-medium text-gray-700 mb-1">
                  Language
                </label>
                <div className="language-selector">
                  <div className="relative">
                    <input
                      id="podcast-language"
                      type="text"
                      className="w-full p-2 border rounded-md"
                      placeholder="Select a language..."
                      value={language || "English"}
                      readOnly
                      onClick={toggleLanguageDropdown}
                    />
                    <svg
                      className={`absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 transition-transform duration-200 ${
                        isLanguageDropdownOpen ? "rotate-180" : ""
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  {isLanguageDropdownOpen && (
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded-md max-h-60 overflow-y-auto">
                      <input
                        type="text"
                        className="w-full p-2 border-b"
                        placeholder="Search languages..."
                        value={languageSearch}
                        onChange={(e) => setLanguageSearch(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        autoFocus
                      />
                      {filteredLanguages.length > 0 ? (
                        filteredLanguages.map((lang) => (
                          <div
                            key={lang}
                            className={`p-2 cursor-pointer hover:bg-gray-100 ${
                              language === lang ? "bg-gray-200" : ""
                            }`}
                            onClick={() => handleLanguageSelect(lang)}
                          >
                            {lang}
                          </div>
                        ))
                      ) : (
                        <div className="p-2 text-gray-500">No languages found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Dialect field */}
              <div className="relative">
                <label htmlFor="podcast-dialect" className="block text-sm font-medium text-gray-700 mb-1">
                  Dialect
                </label>
                <input
                  id="podcast-dialect"
                  className="w-full p-2 border rounded-md"
                  type="text"
                  value={dialect}
                  onChange={handleDialectChange}
                  placeholder="Default: Australian"
                />
              </div>

              {/* Character/Vibe field */}
              <div className="relative">
                <label htmlFor="podcast-character" className="block text-sm font-medium text-gray-700 mb-1">
                  Character/Vibe
                </label>
                <input
                  id="podcast-character"
                  className="w-full p-2 border rounded-md"
                  type="text"
                  value={characterVibe}
                  onChange={handleCharacterVibeChange}
                  placeholder="Podcast host"
                />
                <p className="text-xs text-gray-500 mt-1">
                  Be Creative (e.g., True crime buff, Santa, Pirate)
                </p>
              </div>

              {/* Voice Style field */}
              <div className="relative">
                <label htmlFor="podcast-voice" className="block text-sm font-medium text-gray-700 mb-1">
                  Voice Style
                </label>
                <select
                  id="podcast-voice"
                  className="w-full p-2 border rounded-md"
                  value={voiceType}
                  onChange={handleVoiceTypeChange}
                >
                  {voiceTypeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option === "any" ? "Any Voice" : option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-black text-white py-3 rounded-full hover:bg-gray-800 transition-colors duration-200 mt-6"
              disabled={!topic || !duration}
            >
              Generate Podcast
            </button>
          </form>
        );

      case "processing":
        return (
          <div className="space-y-4">
            <div className="w-full bg-gray-100 p-4 mb-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 truncate">{displayTopic}</h3>
            </div>

            <div className="text-center p-8 bg-gray-50 rounded-lg">
              <div className="flex flex-col items-center">
                <p className="text-gray-700 font-medium text-lg mb-3 animate-pulse">
                  {status === "cancelling"
                    ? "Cancelling..."
                    : (currentStep
                        ? getDisplayStepName(currentStep)
                        : "Processing...")}
                </p>

                <div className="flex mt-4 space-x-2">
                  <div
                    className="w-2 h-2 rounded-full bg-blue-600 animate-bounce"
                    style={{ animationDelay: "0ms" }}
                  ></div>
                  <div
                    className="w-2 h-2 rounded-full bg-blue-600 animate-bounce"
                    style={{ animationDelay: "300ms" }}
                  ></div>
                  <div
                    className="w-2 h-2 rounded-full bg-blue-600 animate-bounce"
                    style={{ animationDelay: "600ms" }}
                  ></div>
                </div>

                {/* Cancel Button - only show if not already cancelling */}
                {status !== "cancelling" && (
                  <button
                    onClick={handleCancelGeneration}
                    className="mt-6 px-6 py-2 bg-red-600 text-white rounded-full hover:bg-red-700 transition-colors duration-200"
                  >
                    Cancel Generation
                  </button>
                )}

                {/* Show cancelling message */}
                {status === "cancelling" && (
                  <p className="mt-4 text-gray-600">
                    Cancelling podcast generation...
                  </p>
                )}
              </div>
            </div>
          </div>
        );

      case "completed":
        return (
          <div className="space-y-4">
            <div className="w-full bg-gray-100 p-4 mb-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 truncate">{displayTopic}</h3>
            </div>

            {audioUrl && (
              <CustomAudioPlayer src={audioUrl} onError={handleAudioError} />
            )}

            <div className="flex space-x-2">
              <button
                onClick={() => setActiveTab("transcript")}
                className={`flex-1 py-2 px-4 text-center font-semibold transition-colors duration-200 ${
                  activeTab === "transcript"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200 text-black hover:bg-gray-300"
                }`}
              >
                Summary
              </button>
              <button
                onClick={() => setActiveTab("references")}
                className={`flex-1 py-2 px-4 text-center font-semibold transition-colors duration-200 ${
                  activeTab === "references"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200 text-black hover:bg-gray-300"
                }`}
              >
                References
              </button>
            </div>

            <div className="rounded-lg max-h-96 overflow-y-auto">
              {activeTab === "transcript" ? (
                result?.content ? (
                  <PodcastSummary
                    summary={result.content.summary || ""}
                    topicSummaries={result.content.topic_summaries || []}
                  />
                ) : (
                  <p className="text-gray-500 italic h-12 flex items-center justify-center">
                    {podCastError ? podCastError : "No summary available."}
                  </p>
                )
              ) : (
                <div className="p-2">
                  {renderReferences(
                    typeof result?.content?.references === "string"
                      ? result.content.references
                      : result?.content?.references
                        ? JSON.stringify(result.content.references)
                        : "",
                  )}
                </div>
              )}
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleNewPodcast}
                className="bg-[#3331C5] text-white px-6 py-3 rounded-full hover:bg-[#3331C5] transition-colors duration-200"
              >
                Generate Another Podcast
              </button>
            </div>
          </div>
        );

      case "failed":
        return (
          <div className="space-y-4">
            <div className="w-full bg-gray-100 p-4 mb-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-900 truncate">{displayTopic}</h3>
            </div>

            <Alert variant="error">
              {error || "An error occurred while generating the podcast."}
            </Alert>
            <button
              onClick={handleNewPodcast}
              className="w-full bg-blue-600 text-white py-3 rounded-full hover:bg-blue-700 transition-colors duration-200"
            >
              Try Again
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto">
      {error && status !== "failed" && <Alert variant="error">{error}</Alert>}
      {renderContent()}
    </div>
  );
};

export default GeneratePodcast;
