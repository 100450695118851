import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useLibrary } from "../context/LibraryContext";

// Updated CustomAudioPlayer component
const CustomAudioPlayer = ({ src, onError }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [audioBlob, setAudioBlob] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const fetchAudio = async (url) => {
    try {
      const token = localStorage.getItem("token");
      if (!url || !token) {
        throw new Error("Missing URL or authentication token");
      }

      // Ensure we're using the correct audio URL
      if (!url.includes("/api/audio/db/")) {
        console.error("Invalid audio URL format:", url);
        throw new Error("Invalid audio URL format");
      }

      const fullUrl = url.startsWith("http")
        ? url
        : `${process.env.REACT_APP_API_URL || ""}${url}`;

      console.log("Fetching audio from:", fullUrl);

      const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "audio/mpeg, application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      setAudioBlob(audioUrl);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching audio:", error);
      if (retryCount < maxRetries) {
        setRetryCount((prev) => prev + 1);
        setTimeout(() => fetchAudio(url), 1000 * (retryCount + 1)); // Exponential backoff
      } else {
        onError?.(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (src) {
      setIsLoading(true);
      setRetryCount(0);
      fetchAudio(src);
    }

    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [src]);

  const handleAudioError = (e) => {
    console.error("Audio playback error:", e);
    onError?.(e);
  };

  return (
    <div className="audio-player-container relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 rounded-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      )}
      {audioBlob && (
        <AudioPlayer
          src={audioBlob}
          onError={handleAudioError}
          showJumpControls={true}
          showSkipControls={false}
          showFilledVolume={true}
          className="rounded-lg"
          customStyles={{
            progressBar: {
              backgroundColor: "#E5E7EB",
            },
            progressBarHeight: 2,
            trackArtistColor: "#4B5563",
            currentTimeColor: "#4B5563",
            durationColor: "#4B5563",
          }}
        />
      )}
    </div>
  );
};

// Update the ShareButton component with the correct URL handling
const ShareButton = ({ itemId }) => {
  const [isSharing, setIsSharing] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [showCopied, setShowCopied] = useState(false);
  const [error, setError] = useState(null);

  const handleShare = async () => {
    try {
      setIsSharing(true);
      setError(null);
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/library/${itemId}/share`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data?.share_id) {
        const shareUrl = `${window.location.origin}/shared/${response.data.share_id}`;
        setShareUrl(shareUrl);
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      setError(error.message || 'Failed to create share link');
    } finally {
      setIsSharing(false);
    }
  };

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(shareUrl);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setError('Failed to copy to clipboard');
    }
  };

  return (
    <div className="mt-4">
      {error && (
        <div className="text-red-500 text-sm mb-2">{error}</div>
      )}
      
      {!shareUrl ? (
        <button
          onClick={handleShare}
          disabled={isSharing}
          className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-md transition-colors
            ${isSharing ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
        >
          {isSharing ? (
            <>
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Generating link...
            </>
          ) : (
            <>
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
              Share Podcast
            </>
          )}
        </button>
      ) : (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={shareUrl}
              readOnly
              className="flex-1 p-2 border rounded bg-gray-50"
            />
            <button
              onClick={handleCopy}
              className={`px-4 py-2 text-sm font-medium text-white rounded-md transition-colors
                ${showCopied ? 'bg-green-600' : 'bg-blue-600 hover:bg-blue-700'}`}
            >
              {showCopied ? 'Copied!' : 'Copy'}
            </button>
          </div>
          <button
            onClick={() => setShareUrl('')}
            className="text-sm text-gray-500 hover:text-gray-700"
          >
            Generate new link
          </button>
        </div>
      )}
    </div>
  );
};

const Library = () => {
  const [selectedItems, setSelectedItems] = useState({});
  const [expandedId, setExpandedId] = useState(null);
  const [activeTab, setActiveTab] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [audioErrors, setAudioErrors] = useState({});
  const { libraryItems } = useLibrary();

  // Updated fetchItemDetails function
  const fetchItemDetails = useCallback(async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.get(`/api/library/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      const itemData = response.data;

      // Log the item data for debugging
      console.log("Fetched library item:", itemData);

      // Ensure audio_url is properly formatted
      if (itemData.audio_url) {
        // Remove any template literals
        itemData.audio_url = itemData.audio_url.replace(/[{}]/g, "");

        // Ensure it points to the correct endpoint
        if (!itemData.audio_url.includes("/api/audio/db/")) {
          console.error(
            "Invalid audio URL format in library item:",
            itemData.audio_url,
          );
        }

        // Add base URL if needed
        if (!itemData.audio_url.startsWith("http")) {
          itemData.audio_url = `${process.env.REACT_APP_API_URL || ""}${itemData.audio_url}`;
        }
      }

      return itemData;
    } catch (error) {
      console.error("Error fetching podcast details:", error);
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/auth";
      }
      throw error;
    }
  }, []);

  const handleExpand = async (id) => {
    if (expandedId === id) {
      setExpandedId(null);
      return;
    }

    setExpandedId(id);
    setActiveTab((prev) => ({ ...prev, [id]: "transcript" }));
    setAudioErrors((prev) => ({ ...prev, [id]: null })); // Reset errors

    if (!selectedItems[id]) {
      setIsLoading(true);
      try {
        const itemData = await fetchItemDetails(id);
        if (itemData) {
          setSelectedItems((prev) => ({ ...prev, [id]: itemData }));
        }
      } catch (error) {
        setAudioErrors((prev) => ({
          ...prev,
          [id]: "Failed to load podcast details. Please try again.",
        }));
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Updated handleAudioError function
  const handleAudioError = (id, error) => {
    console.error(`Audio playback error for podcast ${id}:`, error);

    let errorMessage = "Failed to play audio. Please try again later.";

    if (error.response) {
      switch (error.response.status) {
        case 401:
          errorMessage = "Your session has expired. Please sign in again.";
          localStorage.removeItem("token");
          window.location.href = "/auth";
          break;
        case 403:
          errorMessage = "You don't have permission to play this audio.";
          break;
        case 404:
          errorMessage = "Audio file not found. Please contact support.";
          break;
        case 500:
          errorMessage = "Server error. Please try again later.";
          break;
        default:
          errorMessage = `Error playing audio (${error.response.status}). Please try again.`;
      }
    } else if (error.request) {
      errorMessage = "Network error. Please check your connection.";
    }

    setAudioErrors((prev) => ({
      ...prev,
      [id]: errorMessage,
    }));
  };

  const renderContent = (item, activeTabValue) => {
    if (!item) return null;

    if (activeTabValue === "transcript") {
      return item.summary ? (
        <div className="bg-gray-100 p-2">
          <div className="max-h-96 overflow-y-auto">
            {item.summary.split("\n").map((paragraph, idx) => (
              <p
                key={idx}
                className="whitespace-pre-wrap text-sm bg-white p-2 border-b last:border-b-0"
              >
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-gray-500 italic h-12 flex items-center justify-center">
          No summary available for this podcast.
        </div>
      );
    } else {
      // Reference rendering logic
      const formatReferences = (referenceText) => {
        if (!referenceText) return null;
        const lines = referenceText.split("\n").filter((line) => line.trim());

        return (
          <div className="bg-gray-100 p-2">
            <div className="max-h-96 overflow-y-auto">
              {lines.map((line, index) => {
                const citationRegex = /\[(.*?)\]/g;
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                let processedLine = line;

                processedLine = processedLine.replace(
                  citationRegex,
                  (match) => `<strong>${match}</strong>`,
                );

                processedLine = processedLine.replace(
                  urlRegex,
                  (url) =>
                    `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline break-all">${url}</a>`,
                );

                return (
                  <div
                    key={index}
                    className="mb-3 last:mb-0 pl-8 relative bg-white p-2 border-b last:border-b-0"
                  >
                    <span className="absolute left-2 top-2 font-bold">
                      {index + 1}.
                    </span>
                    <div
                      dangerouslySetInnerHTML={{ __html: processedLine }}
                      className="text-sm reference-line ml-4"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      };

      return item.reference_list ? (
        formatReferences(item.reference_list)
      ) : (
        <div className="text-gray-500 italic h-12 flex items-center justify-center">
          No references available for this podcast.
        </div>
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setExpandedId(null); // Close expanded item when searching
  };

  const filteredLibraryItems = libraryItems.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  // Loading spinner component
  const LoadingSpinner = () => (
    <div className="flex justify-center items-center py-4">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
    </div>
  );

  // Add cleanup effect
  useEffect(() => {
    return () => {
      // Cleanup any existing audio blobs
      Object.values(selectedItems).forEach((item) => {
        if (item.audioUrl) {
          URL.revokeObjectURL(item.audioUrl);
        }
      });
    };
  }, [selectedItems]);

  // Add authentication check effect
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/auth";
    }
  }, []);

  return (
    <div className="w-full max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Your Podcast Library</h2>

      <input
        type="text"
        placeholder="Search podcasts..."
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setExpandedId(null);
        }}
        className="w-full p-2 mb-4 border rounded-lg"
      />

      <div className="space-y-4">
        {libraryItems
          .filter((item) =>
            item.title.toLowerCase().includes(searchQuery.toLowerCase()),
          )
          .map((item) => (
            <div
              key={item.id}
              className="border rounded-lg shadow-sm overflow-hidden bg-white"
            >
              <button
                onClick={() => handleExpand(item.id)}
                className="w-full p-4 text-left font-semibold bg-gray-50 hover:bg-gray-100 transition-colors duration-200 flex justify-between items-center"
              >
                <span>{item.title}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 ${
                    expandedId === item.id ? "rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {expandedId === item.id && (
                <div className="p-4 space-y-4">
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    selectedItems[item.id] && (
                      <>
                        <CustomAudioPlayer
                          src={selectedItems[item.id].audio_url}
                          onError={(e) => handleAudioError(item.id, e)}
                        />

                        {audioErrors[item.id] && (
                          <div className="text-red-500 text-sm text-center my-2">
                            {audioErrors[item.id]}
                          </div>
                        )}

                        <div className="flex space-x-2">
                          <button
                            onClick={() =>
                              setActiveTab((prev) => ({
                                ...prev,
                                [item.id]: "transcript",
                              }))
                            }
                            className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                              activeTab[item.id] === "transcript"
                                ? "bg-[#3331C5] text-white"
                                : "bg-gray-200 hover:bg-gray-300 text-gray-800"
                            }`}
                          >
                            Summary
                          </button>
                          <button
                            onClick={() =>
                              setActiveTab((prev) => ({
                                ...prev,
                                [item.id]: "references",
                              }))
                            }
                            className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                              activeTab[item.id] === "references"
                                ? "bg-[#3331C5] text-white"
                                : "bg-gray-200 hover:bg-gray-300 text-gray-800"
                            }`}
                          >
                            References
                          </button>
                        </div>

                        <div className="rounded-lg overflow-y-auto">
                          {renderContent(
                            selectedItems[item.id],
                            activeTab[item.id],
                          )}
                        </div>

                        {/* Add the ShareButton here */}
                        <ShareButton itemId={item.id} />
                      </>
                    )
                  )}
                </div>
              )}
            </div>
          ))}

        {libraryItems.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No podcasts in your library yet. Generate your first podcast to get
            started!
          </div>
        )}
      </div>
    </div>
  );
};

export default Library;
