import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SharedPodcastView = () => {
  const { shareId } = useParams();
  const [podcastData, setPodcastData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('transcript');
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioLoading, setAudioLoading] = useState(false);

  const fetchAudio = async (audioUrl) => {
    try {
      setAudioLoading(true);
      const audioId = audioUrl.split('/api/audio/db/')[1];
      const fullUrl = `${process.env.REACT_APP_API_URL}/api/shared/audio/${audioId}`;
      
      const response = await fetch(fullUrl);
      if (!response.ok) throw new Error('Failed to fetch audio');
      
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setAudioBlob(blobUrl);
    } catch (error) {
      console.error('Audio fetch error:', error);
      setError('Failed to load audio');
    } finally {
      setAudioLoading(false);
    }
  };

  useEffect(() => {
    const fetchSharedPodcast = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/shared/${shareId}`
        );
        
        if (!response.data) {
          throw new Error('No podcast data received');
        }
        
        setPodcastData(response.data);
        
        if (response.data.audio_url) {
          await fetchAudio(response.data.audio_url);
        }
      } catch (error) {
        console.error('Error fetching shared podcast:', error);
        setError(error.response?.data?.detail || 'Failed to load podcast');
      } finally {
        setIsLoading(false);
      }
    };

    if (shareId) {
      fetchSharedPodcast();
    }

    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [shareId]);

  // Function to convert URLs in text to clickable links
  const convertUrlsToLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Split the text by URLs
    const parts = text.split(urlRegex);
    
    return parts.map((part, index) => {
      // If the part matches a URL, render it as a link
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline break-words"
          >
            {part}
          </a>
        );
      }
      // Otherwise render it as regular text
      return <span key={index}>{part}</span>;
    });
  };

  const renderContent = () => {
    if (activeTab === 'transcript') {
      return podcastData?.summary ? (
        <div className="bg-gray-100 p-2">
          <div className="max-h-96 overflow-y-auto">
            {podcastData.summary.split('\n').map((paragraph, idx) => (
              <p key={idx} className="whitespace-pre-wrap text-sm bg-white p-2 border-b last:border-b-0">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-gray-500 italic h-12 flex items-center justify-center">
          No summary available for this podcast.
        </div>
      );
    } else {
      return podcastData?.reference_list ? (
        <div className="bg-gray-100 p-2">
          <div className="max-h-96 overflow-y-auto">
            {podcastData.reference_list.split('\n').map((reference, index) => (
              <div key={index} className="mb-3 last:mb-0 pl-8 relative bg-white p-2 border-b last:border-b-0">
                <span className="absolute left-2 top-2 font-bold">{index + 1}.</span>
                <div className="text-sm reference-line ml-4">
                  {convertUrlsToLinks(reference)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-gray-500 italic h-12 flex items-center justify-center">
          No references available for this podcast.
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-[#020209] to-[#3331C5]">
      <Header />
      <main className="flex-grow py-8">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        ) : error ? (
          <div className="flex justify-center items-center h-full">
            <div className="text-center p-8 bg-red-50 rounded-lg">
              <h2 className="text-xl font-bold text-red-800 mb-2">Error</h2>
              <p className="text-red-600">{error}</p>
            </div>
          </div>
        ) : !podcastData ? (
          <div className="flex justify-center items-center h-full">
            <div className="text-center p-8">
              <p className="text-white">No podcast found</p>
            </div>
          </div>
        ) : (
          <div className="max-w-3xl mx-auto px-4">
            <div className="bg-white rounded-lg shadow p-6">
              <h1 className="text-2xl font-bold mb-6">{podcastData.title}</h1>
              
              {audioBlob && (
                <div className="mb-6 relative">
                  {audioLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 rounded-lg">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                  ) : (
                    <AudioPlayer
                      src={audioBlob}
                      onError={(e) => {
                        console.error('Audio playback error:', e);
                        setError("Failed to play audio. Please try again.");
                      }}
                      showJumpControls={true}
                      showSkipControls={false}
                      showFilledVolume={true}
                      className="rounded-lg"
                      autoPlayAfterSrcChange={false}
                    />
                  )}
                </div>
              )}

              <div className="flex space-x-2 mb-4">
                <button
                  onClick={() => setActiveTab('transcript')}
                  className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                    activeTab === 'transcript'
                      ? 'bg-[#3331C5] text-white'
                      : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                  }`}
                >
                  Summary
                </button>
                <button
                  onClick={() => setActiveTab('references')}
                  className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                    activeTab === 'references'
                      ? 'bg-[#3331C5] text-white'
                      : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                  }`}
                >
                  References
                </button>
              </div>

              <div className="bg-gray-50 rounded-lg p-4">
                {renderContent()}
              </div>
            </div>
          </div>
        )}
      </main>
      <div className='text-white'>
       <Footer page="shared"/>
      </div>
     
    </div>
  );
};

export default SharedPodcastView;