import React, { useState } from "react";
import axios from "axios";

const FeedbackForm = () => {
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "/api/feedback",
        { topic, description },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setMessage("Feedback submitted successfully!");
      setTopic("");
      setDescription("");
    } catch (error) {
      setError("Error submitting feedback. Please try again.");
      console.error("Error:", error.response?.data || error.message);
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Provide Feedback</h2>
      <div className="flex flex-col items-center justify-center bg-white overflow-x-hidden">
        <form className="flex flex-col w-[100%]" onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Topic"
              className="w-full p-3 rounded-lg bg-gray-100 text-lg"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <textarea
              placeholder="Description"
              className="w-full p-3 rounded-lg bg-gray-100 text-lg h-32 resize-none"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="p-3 rounded-full bg-black text-white text-lg hover:bg-gray-800"
          >
            Submit
          </button>
        </form>
        {message && <p className="text-green-600 mt-4">{message}</p>}
        {error && <p className="text-red-600 mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default FeedbackForm;
