import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const AuthForm = () => {
  const [activeTab, setActiveTab] = useState("signup");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otpValue, setOTPValue] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpRequestStatus, setOtpRequestStatus] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [cooldownTimer, setCooldownTimer] = useState(0);
  const [savedPassword, setSavedPassword] = useState('');
  const [forgotPasswordStep, setForgotPasswordStep] = useState('email');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const { login, isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn || isAuthorized) {
      navigate("/get-started", { replace: true });
    }
  }, [isLoggedIn, isAuthorized, navigate]);

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "username":
        if (!value) {
          error = "Username is required";
        } else if (value.length < 3) {
          error = "Username must be at least 3 characters long";
        }
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Please enter a valid email address";
        }
        break;
      case "password":
        if (!value) {
          error = "Password is required";
        } else if (value.length < 6) {
          error = "Password must be at least 6 characters long";
        }
        break;
      case "confirmPassword":
        if (!value) {
          error = "Please confirm your password";
        } else if (value !== formData.password) {
          error = "Passwords do not match";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const formatErrorMessage = (error) => {
    if (!error) return "";
    if (error.loc && error.msg) return error.msg;
    if (typeof error === 'string') return error;
    if (error.response?.data) {
      const data = error.response.data;
      if (typeof data === 'string') return data;
      if (data.detail) return data.detail;
      if (Array.isArray(data)) return data.map(err => err.msg).join(', ');
    }
    return "An unexpected error occurred";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const startCooldownTimer = () => {
    const timer = setInterval(() => {
      setCooldownTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Sign Up handler
  const handleSignup = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL || ''}/api/register`,
        {
          username: formData.username,
          email: formData.email,
          password: formData.password
        }
      );
      
      setSuccessMessage("Signup successful! Please sign in.");
      setFormData({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
      setActiveTab("signin");
    } catch (error) {
      throw error; // Let handleSubmit handle the error
    }
  };

  // Sign In handler
  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL || ''}/api/auth/signin-request`,
            {
                email: formData.email,
                password: formData.password
            }
        );
        
        if (response.data.status === "success") {
            setShowOTPInput(true);
            setTempEmail(formData.email);
            setSavedPassword(formData.password);
            setOtpRequestStatus(response.data.message);
            setCooldownTimer(response.data.cooldown || 30);
            startCooldownTimer();
            
            // Clear password field but keep email
            setFormData(prev => ({
                ...prev,
                password: ''
            }));
            
            // Clear any existing errors
            setErrors({});
            setOtpError("");
        }
    } catch (error) {
        const errorMessage = formatErrorMessage(error.response?.data);
        
        if (error.response?.status === 429) {
            // Handle rate limiting
            setOtpError(error.response.data.detail);
        } else {
            setErrors({ form: errorMessage });
        }
        
        setShowOTPInput(false);
    } finally {
        setIsLoading(false);
    }
};

  // Forgot Password handlers
  const handleShowForgotPassword = () => {
    setFormData(prev => ({
      ...prev,
      email: '',
      password: '',
      confirmPassword: ''
    }));
    
    setErrors({});
    setOtpError('');
    
    setOTPValue('');
    setOtpRequestStatus('');
    
    setForgotPasswordStep('email');
    setShowForgotPassword(true);
    setShowOTPInput(false);
    
    setSuccessMessage('');
    
    setCooldownTimer(0);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL || ''}/api/auth/forgot-password-otp`,
        {
          email: formData.email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.exists === false) {
        setErrors({
          form: "This email is not registered. Please check the email or sign up."
        });
        // Clear the email field after 3 seconds
        setTimeout(() => {
          setFormData(prev => ({ ...prev, email: '' }));
          setErrors({});
        }, 3000);
        return;
      }
      
      if (response.data.success) {
        setTempEmail(formData.email);
        setOtpRequestStatus("Password reset OTP sent successfully. Please check your email.");
        setCooldownTimer(response.data.cooldown || 30);
        startCooldownTimer();
        setForgotPasswordStep('otp');
      }
    } catch (error) {
      console.error("OTP request error:", error);
      const errorMessage = formatErrorMessage(error.response?.data);
      setErrors({ form: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  // OTP handlers
  const handleVerifyOTP = async () => {
    setIsLoading(true);
    setOtpError("");
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL || ''}/api/auth/verify-signin-otp`,
        {
          email: tempEmail,
          otp: otpValue
        }
      );
      
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        await login({
          token: response.data.access_token,
          type: response.data.token_type,
          user: response.data.user
        });
        
        // Reset all states
        setIsAuthorized(true);
        setShowOTPInput(false);
        setShowForgotPassword(false);
        setOTPValue("");
        setTempEmail("");
        setOtpRequestStatus("");
        
        navigate("/get-started", { replace: true });
      }
    } catch (error) {
      setOtpError(formatErrorMessage(error.response?.data));
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (cooldownTimer > 0) {
      setOtpError(`Please wait ${cooldownTimer} seconds before requesting a new OTP`);
      return;
    }

    setIsLoading(true);
    setOtpError("");
    
    const endpoint = showForgotPassword 
      ? '/api/auth/forgot-password-otp'
      : '/api/auth/signin-request';
    
    try {
      const requestData = showForgotPassword 
        ? { email: tempEmail }
        : { email: tempEmail, password: savedPassword };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL || ''}${endpoint}`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      setOtpRequestStatus("New OTP sent successfully. Please check your email.");
      if (response.data.cooldown) {
        setCooldownTimer(response.data.cooldown);
        startCooldownTimer();
      }
    } catch (error) {
      setOtpError(error.response?.status === 429 
        ? error.response.data.detail 
        : formatErrorMessage(error.response?.data));
    } finally {
      setIsLoading(false);
    }
  };

  const renderOTPForm = () => (
    <div className="space-y-4">
      <div className="text-sm text-gray-600 mb-4">
        Enter OTP sent to: <span className="font-semibold">{tempEmail}</span>
      </div>

      {otpRequestStatus && (
        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{otpRequestStatus}</span>
        </div>
      )}

      <div>
        <input
          type="text"
          placeholder="Enter 6-digit OTP"
          className={`w-full p-3 rounded-lg bg-gray-100 ${
            otpError ? "border-red-500" : ""
          }`}
          value={otpValue}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, '').slice(0, 6);
            setOTPValue(value);
            if (otpError) setOtpError(""); // Clear error when user types
          }}
          maxLength={6}
          inputMode="numeric"
          pattern="[0-9]*"
        />
        {otpError && (
          <p className="text-red-500 text-sm mt-1">{otpError}</p>
        )}
      </div>

      <button
        onClick={handleVerifyOTP}
        disabled={isLoading || otpValue.length !== 6}
        className="w-full p-3 rounded-lg bg-[#3331C5] text-white font-semibold hover:bg-[#2826a3] transition-colors duration-200 disabled:bg-gray-400"
      >
        {isLoading ? "Verifying..." : "Verify OTP"}
      </button>

      <div className="flex flex-col items-center space-y-2">
        <button
          onClick={handleResendOTP}
          disabled={isLoading || cooldownTimer > 0}
          className="text-[#3331C5] hover:underline disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {cooldownTimer > 0
            ? `Resend OTP (${cooldownTimer}s)`
            : "Resend OTP"}
        </button>

        <button
          onClick={() => {
            setShowOTPInput(false);
            setOTPValue("");
            setOtpError("");
            setOtpRequestStatus("");
          }}
          className="text-gray-600 hover:underline text-sm"
        >
          Use Different Email
        </button>
      </div>
    </div>
  );

  const renderForgotPasswordForm = () => (
    <div>
      <h2 className="text-2xl font-bold mb-6 text-center">
        {forgotPasswordStep === 'email' ? 'Forgot Password' : 'Verify OTP'}
      </h2>
      
      {forgotPasswordStep === 'email' ? (
        <form onSubmit={handleForgotPasswordSubmit} className="space-y-4">
          <div>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              className={`w-full p-3 rounded-lg bg-gray-100 ${
                errors.email || errors.form ? "border-red-500" : ""
              }`}
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>
          
          {errors.form && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              <span className="block sm:inline">{errors.form}</span>
            </div>
          )}

          {otpRequestStatus && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
              <span className="block sm:inline">{otpRequestStatus}</span>
            </div>
          )}
          
          <button
            type="submit"
            disabled={isLoading}
            className="w-full p-3 rounded-lg bg-[#3331C5] text-white font-semibold hover:bg-[#2826a3] transition-colors duration-200 disabled:bg-gray-400"
          >
            {isLoading ? "Sending..." : "Send OTP"}
          </button>

          <div className="text-center mt-4">
            <button
              type="button"
              onClick={() => {
                setShowForgotPassword(false);
                setErrors({});
                setForgotPasswordStep('email');
              }}
              className="text-[#3331C5] hover:underline"
            >
              Back to Sign In
            </button>
          </div>
        </form>
      ) : (
        renderOTPForm()
      )}
    </div>
  );

  const renderAuthForm = () => (
    <>
      <div className="flex mb-6">
        <button
          className={`w-1/2 py-2 px-4 text-center font-semibold transition-colors duration-200 ${
            activeTab === "signup"
              ? "bg-[#3331C5] text-white"
              : "bg-gray-200 text-black hover:bg-gray-300"
          }`}
          onClick={() => handleTabChange("signup")}
        >
          SIGN UP
        </button>
        <button
          className={`w-1/2 py-2 px-4 text-center font-semibold transition-colors duration-200 ${
            activeTab === "signin"
              ? "bg-[#3331C5] text-white"
              : "bg-gray-200 text-black hover:bg-gray-300"
          }`}
          onClick={() => handleTabChange("signin")}
        >
          SIGN IN
        </button>
      </div>

      {!showOTPInput ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          {activeTab === "signup" && (
            <div>
              <input
                type="text"
                name="username"
                placeholder="Username"
                className={`w-full p-3 rounded-lg bg-gray-100 ${
                  errors.username ? "border-red-500" : ""
                }`}
                value={formData.username}
                onChange={handleChange}
              />
              {errors.username && (
                <p className="text-red-500 text-sm mt-1">{errors.username}</p>
              )}
            </div>
          )}
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className={`w-full p-3 rounded-lg bg-gray-100 ${
                errors.email ? "border-red-500" : ""
              }`}
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>
          <div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              className={`w-full p-3 rounded-lg bg-gray-100 ${
                errors.password ? "border-red-500" : ""
              }`}
              value={formData.password}
              onChange={handleChange}
              required
            />
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>
          {activeTab === "signup" && (
            <div>
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
                className={`w-full p-3 rounded-lg bg-gray-100 ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.confirmPassword}
                </p>
              )}
            </div>
          )}
          
          {errors.form && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
              <span className="block sm:inline">{errors.form}</span>
            </div>
          )}

          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full p-3 rounded-lg bg-[#3331C5] text-white font-semibold hover:bg-[#2826a3] transition-colors duration-200 disabled:bg-gray-400"
          >
            {isLoading ? "Please wait..." : (activeTab === "signup" ? "SIGN UP" : "SIGN IN")}
          </button>

          {activeTab === "signin" && (
            <div className="text-center mt-4">
              <button
                type="button"
                onClick={handleShowForgotPassword}
                className="text-[#3331C5] hover:underline text-sm"
              >
                Forgot Password?
              </button>
            </div>
          )}
        </form>
      ) : (
        renderOTPForm()
      )}
    </>
  );

  // Add handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields first
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      // Only validate confirmPassword for signup
      if (activeTab === 'signin' && key === 'confirmPassword') return;
      // Only validate username for signup
      if (activeTab === 'signin' && key === 'username') return;
      
      const error = validateField(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    // If there are validation errors, show them and stop
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    setErrors({});

    try {
      if (activeTab === "signup") {
        await handleSignup();
      } else {
        await handleSignInSubmit(e);
      }
    } catch (error) {
      const errorMessage = formatErrorMessage(error);
      setErrors({ form: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  // Add handleTabChange function
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setFormData({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setErrors({});
    setSuccessMessage("");
    setShowOTPInput(false);
    setOTPValue("");
    setOtpError("");
    setOtpRequestStatus("");
  };

  // Add handleBackToLogin function
  const handleBackToLogin = () => {
    setShowForgotPassword(false);
    setForgotPasswordStep('email');
    setErrors({});
    setOTPValue('');
    setOtpError('');
    setOtpRequestStatus('');
    setFormData(prev => ({
      ...prev,
      email: '',
      password: ''
    }));
    setCooldownTimer(0);
  };

  // Add cleanup effect
  useEffect(() => {
    return () => {
      setShowForgotPassword(false);
      setForgotPasswordStep('email');
      setOTPValue('');
      setOtpError('');
      setOtpRequestStatus('');
      setCooldownTimer(0);
    };
  }, []);

  // Main component return
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow flex justify-center">
        <div className="w-full max-w-7xl">
          <div className="flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md border-4 border-[#3331C5]">
              {isAuthorized && (
                <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
                  <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#3331C5] mx-auto"></div>
                    <p className="mt-4 text-gray-600">Redirecting to dashboard...</p>
                  </div>
                </div>
              )}

              {showForgotPassword ? (
                renderForgotPasswordForm()
              ) : (
                renderAuthForm()
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AuthForm;