// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-player .rhap_progress-section {
    position: relative;
    margin: 0 0 20px 0;
  }
  
  .custom-player .rhap_controls-section {
    margin-top: 10px;
  }
  
  .custom-player .rhap_main-controls-button {
    color: #000;
    font-size: 28px;
  }
  
  .custom-player .rhap_volume-button {
    color: #000;
  }
  
  .custom-player .rhap_progress-filled {
    background-color: #3B82F6;
  }
  
  .custom-player .rhap_progress-indicator {
    background: #3B82F6;
    width: 12px;
    height: 12px;
    top: -5px;
    margin-left: -6px;
  }

.reference-content a {
    word-break: break-all;
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
}

.reference-content a:hover {
    text-decoration-line: underline;
}
.reference-content strong {
    font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/AudioPlayer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,iBAAiB;EACnB;;AAGA;IAAA,qBAA8C;IAA9C,oBAA8C;IAA9C;AAA8C;;AAA9C;IAAA;AAA8C;AAG9C;IAAA;AAAgB","sourcesContent":[".custom-player .rhap_progress-section {\n    position: relative;\n    margin: 0 0 20px 0;\n  }\n  \n  .custom-player .rhap_controls-section {\n    margin-top: 10px;\n  }\n  \n  .custom-player .rhap_main-controls-button {\n    color: #000;\n    font-size: 28px;\n  }\n  \n  .custom-player .rhap_volume-button {\n    color: #000;\n  }\n  \n  .custom-player .rhap_progress-filled {\n    background-color: #3B82F6;\n  }\n  \n  .custom-player .rhap_progress-indicator {\n    background: #3B82F6;\n    width: 12px;\n    height: 12px;\n    top: -5px;\n    margin-left: -6px;\n  }\n\n.reference-content a {\n  @apply text-blue-600 hover:underline break-all;\n}\n.reference-content strong {\n  @apply font-bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
