// App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PodFastForm from "./pages/PodFastForm";
import AuthForm from "./pages/AuthForm";
import About from "./pages/About";
import GeneratePodcast from "./components/GeneratePodcast";
import Library from "./components/Library";
import { AuthProvider } from "./context/AuthContext";
import { LibraryProvider } from "./context/LibraryContext";
import HomePage from "./pages/HomeNew";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ResetPassword from "./pages/ResetPassword";
import AutoLogout from "./components/AutoLogout";


function App() {
  const [libraryUpdateTrigger, setLibraryUpdateTrigger] = useState(0);

  const handlePodcastGenerated = () => {
    setLibraryUpdateTrigger((prev) => prev + 1);
  };

  return (
    <>
      <AutoLogout />
      <AuthProvider>
        <LibraryProvider>
          <Router>
            <Routes>
              <Route path="/auth" element={<AuthForm />} />
              <Route path="/get-started" element={<PodFastForm />} />
              <Route path="/about" element={<About />} />
              <Route path="/" element={<HomePage page="home" />} />
              <Route path="/terms" element={<TermsOfUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/generate-podcast"
                element={
                  <GeneratePodcast
                    onPodcastGenerated={handlePodcastGenerated}
                  />
                }
              />
              <Route
                path="/library"
                element={<Library updateTrigger={libraryUpdateTrigger} />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </Router>
        </LibraryProvider>
      </AuthProvider>
    </>
  );
}

export default App;
