import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header page="about" />
      <main className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-4 pb-12">
          <h1 className="text-4xl font-bold mb-8 text-center text-black">
            Welcome to Podfast - Where Knowledge Meets Efficiency
          </h1>

          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">
              Our Innovation
            </h2>
            <p className="text-lg text-gray-600">
              Podfast harnesses the power of advanced AI technology to
              revolutionize both podcast creation and learning. Our platform
              transforms complex topics and scattered information into concise,
              engaging podcasts through an innovative AI system, saving you
              valuable time while maximizing knowledge retention.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">
              What Sets Us Apart
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-black">
                  Smart Learning Optimization
                </h3>
                <p className="text-gray-600">
                  Our platform condenses hours of online research into focused,
                  digestible podcast episodes. What might take days to learn
                  through traditional online searching can be absorbed in a
                  fraction of the time through our carefully crafted audio
                  content.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-black">
                  Intelligent Conversation Design
                </h3>
                <p className="text-gray-600">
                  Our sophisticated framework orchestrates natural, engaging
                  dialogues that break down complex topics into clear, coherent
                  discussions while maintaining authentic human-like
                  interactions.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-black">
                  Time-Saving Solution
                </h3>
                <p className="text-gray-600">
                  Say goodbye to endless scrolling and tab-hopping. Podfast
                  aggregates, filters, and presents information in a streamlined
                  audio format, allowing you to learn and stay informed while
                  multitasking.
                </p>
              </div>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">
              Our Vision
            </h2>
            <p className="text-lg text-gray-600">
              We believe in democratizing both content creation and knowledge
              acquisition. Our mission is to help everyone bypass information
              overload through concentrated, well-structured audio content,
              regardless of their technical expertise or prior experience.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">
              The Technology
            </h2>
            <p className="text-lg text-gray-600 mb-4">
              Behind Podfast's intuitive interface lies a sophisticated system
              that combines:
            </p>
            <ul className="list-disc list-inside text-gray-600 ml-4">
              <li>Advanced natural language processing</li>
              <li>Intelligent content curation and synthesis</li>
              <li>Dynamic conversation structuring</li>
              <li>AI framework for coherent narrative flow</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">
              Perfect For
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-black">
                  Busy Professionals
                </h3>
                <p className="text-gray-600">
                  Transform your commute or exercise time into productive
                  learning sessions with concentrated knowledge delivery.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-black">
                  Lifelong Learners
                </h3>
                <p className="text-gray-600">
                  Quickly grasp new subjects and stay updated on your interests
                  through carefully curated and synthesized information.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-black">
                  Knowledge Enthusiasts
                </h3>
                <p className="text-gray-600">
                  Turn any topic that interests you into an engaging podcast
                  without worrying about technical skills or production
                  expertise.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">
              Join Our Community
            </h2>
            <p className="text-lg text-gray-600 mb-4">
              Be part of the future of efficient learning where anyone can
              transform their interests or questions into engaging podcasts.
              Simply input your topic, and let Podfast handle the rest - no
              expertise needed, no learning curve required.
            </p>
            <p className="text-xl font-semibold text-black text-center">
              Experience the evolution of learning with Podfast – where
              AI-powered efficiency meets engaging audio content for everyone.
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default About;
