import React from "react";
import { Link } from "react-router-dom";
function Home() {
  return (
    <section className="container mx-auto px-4 py-16 md:py-24 flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 mb-8 md:mb-0 text-center">
        <h2 className="text-4xl md:text-4xl font-bold mb-4">
          Create Personalised Podcasts from Any Input in Seconds
        </h2>
        <p className="text-xl text-gray-600 mb-6">
          Transform your ideas into easily digestible audio, personalized just
          for you.
        </p>
        <Link
          to="/auth"
          className="inline-flex items-center justify-center px-6 py-3 bg-blue-500 text-white font-semibold rounded-full hover:bg-blue-600 transition duration-300"
        >
          Get Started
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 ml-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>
      <div className="md:w-1/2 flex justify-center">
        <img
          src={require("../assets/images/img.png")}
          alt="Person using PodFast app"
          className="rounded-lg shadow-lg"
        />
      </div>
    </section>
  );
}

export default Home;
