import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TermsOfUse = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header />
      <main className="flex-grow container mx-auto px-4 pb-8">
        <h1 className="text-4xl font-bold mb-8 text-center text-black">
          Terms of Use
        </h1>

        <div className="max-w-none">
          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">1. Acceptance of Terms</h2>
            <p>By accessing or using PodFast, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree with these terms, please do not use our services.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">2. Service Description</h2>
            <p>PodFast is an AI-powered platform that generates podcast-style audio content from user queries. PodFast reserves the right to modify its service offerings at any time without notice.</p>
            <p>The service currently offers:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Maximum of 5 podcast generations per user per day.</li>
              <li>Podcast duration of up to 5 minutes.</li>
              <li>Two content categories: General and News.</li>
              <li>Web-based platform accessibility.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">3. User Eligibility and Account</h2>
            <p>Users must provide accurate registration information and are responsible for updating their details as needed. PodFast may verify registration information at its discretion.</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Minimum age requirement: 13 years.</li>
              <li>Users between 13-18 years require parent/guardian supervision.</li>
              <li>One account per user.</li>
              <li>Users must provide accurate registration information.</li>
              <li>Users are responsible for maintaining account security.</li>
              <li>Immediate notification required for unauthorized account access.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">4. Content Generation and Usage Rights</h2>
            <p>PodFast grants users a limited, non-exclusive, revocable license to use generated content for personal, non-commercial purposes only. Acceptable use of the content ensure that have no rights to create derivative works, downloaded for redistribution and cannot or modify the content in any manner. The content must comply with platform guidelines.</p>
            <h3 className="text-xl font-semibold mt-4 mb-2">Content Limitations</h3>
            <ul className="list-disc pl-6 mb-4">
              <li>Generated content is the intellectual property of PodFast.</li>
              <li>Commercial use of generated content is strictly prohibited.</li>
              <li>Redistribution of content is not permitted for commercial purposes.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">5. Prohibited Activities and Termination</h2>
            <p>PodFast reserves the right to investigate any suspected breach of these terms and may take appropriate actions, including suspending or terminating access. Users shall not:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Attempt to circumvent daily generation limits.</li>
              <li>Generate content promoting hate speech or violence.</li>
              <li>Use the platform for illegal activities.</li>
              <li>Share account access with others.</li>
              <li>Attempt to reverse engineer the platform.</li>
              <li>Upload malicious code or content.</li>
            </ul>
            <p>In the event of any breach, we reserve the right to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Suspend or terminate accounts violating these terms</li>
              <li>Modify or discontinue services without notice</li>
              <li>Block access from specific IP addresses or regions</li>
              <li>Remove any content at our discretion</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">6. Content Moderation</h2>
            <p>PodFast employs AI-driven moderation tools to prevent the creation of inappropriate or harmful content. We reserve the right to monitor and review all generated content to ensure compliance with our standards. Any violations of these standards may result in immediate suspension or termination of the user's account. Users are encouraged to report any inappropriate content by contacting our support team at <a href="mailto:support@podfast.com" className="text-blue-600 hover:text-blue-800 underline">support@podfast.com</a>.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">7. Platform Access</h2>
            <p>PodFast is accessible via web browsers without geographical restrictions; however, service availability may vary by region. Future mobile applications are planned for release and may be subject to different terms.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">8. Disclaimer of Warranties and Limitation of Liability</h2>
            <p>PodFast does not guarantee and thus is not liable to the below:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Technical issues may affect service availability, thus no guarantee of uninterrupted service or data loss.</li>
              <li>No warranty for content accuracy or completeness.</li>
              <li>The accuracy or suitability of any third-party content from which summaries are generated and is not liable for any third-party content errors.</li>
              <li>Service provided "AS IS" and "AS AVAILABLE".</li>
              <li>Direct or indirect damages from platform use.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">9. Intellectual Property</h2>
            <p>All Podast.io and thereby company assets, including trademarks, logos, platform name, and proprietary content including generated content remains PodFast and therein any parent/associated company's property. They are protected under the relevant intellectual property laws of Australia, Canada and India. Unauthorized use of PodFast's trademarks, logos, platform name, or other proprietary marks is strictly prohibited, and may result in legal action under applicable region's intellectual property and copyright laws.</p>
            <p>Users retain ownership of input content.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">10. Changes to Terms</h2>
            <p>These Terms may be updated at any time as Regular review of terms recommended. For significant changes to these terms, PodFast will provide a reasonable notice period, typically 30 days, unless changes are due to legal compliance. Continued use constitutes acceptance of changes.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">11. Governing Law</h2>
            <p>These terms shall be governed by and construed in accordance with the applicable laws of region of use in addition to Australia, India and Canada, without regard to conflicts of law principles.</p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3">12. Contact Information</h2>
            <p>For any Support and privacy issues, please contact us via <a href="mailto:support@podfast.com" className="text-blue-600 hover:text-blue-800 underline">support@podfast.com</a>.</p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
