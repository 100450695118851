import React from "react";
import { Link } from "react-router-dom";

function Footer({ page }) {
  return (
    <footer>
      <div className="container mx-auto px-4 py-6 md:py-8 flex flex-col md:flex-row justify-between items-center">
        <div className="mb-6 md:mb-0 text-center md:text-left flex flex-col md:flex-row md:flex-wrap items-center">
          <p className="text-sm md:text-base mb-2 md:mb-0 md:mr-4">
            &copy; 2024 Podfast.io All rights reserved.
          </p>
          <div className="flex space-x-4">
            <Link to="/terms" className="text-sm md:text-base hover:font-bold">
              Terms of Use
            </Link>
            <Link
              to="/privacy-policy"
              className="text-sm md:text-base hover:font-bold"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0">
          <div className="flex space-x-4 justify-center">
            <a
              href="https://www.linkedin.com/company/podfast-io/"
              target="_blank"
              className="bg-[#0A66C2] text-white p-[2px] rounded"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24">
                <path
                  fill="#0A66C2"
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
                />
                <path
                  fill="white"
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
                />
              </svg>
            </a>

            <a
              href="https://x.com/Podfast_io"
              target="_blank"
              className={`${
                page === "home" || page === "shared"  ? "text-white" : "text-black"
              } hover:font-bold`}
            >
              <svg
                className="w-[28px] h-[28px]"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
