import React, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const TypewriterText = ({ text, delay = 50, onComplete, className }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, delay, text, onComplete]);

  return <span className={className}>{currentText}</span>;
};

const HomePage = () => {
  const [showSecondLine, setShowSecondLine] = useState(false);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const { isLoggedIn } = useAuth();

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-[#020209] to-[#3331C5] text-white">
      <Header page="home" />
      <div className="text-center mb-10">
        <span className="underline text-lg font-medium">
          Early Access Beta - Experience It First 🚀
        </span>
      </div>
      <main className="flex-grow container mx-auto px-4">
        <div className="flex flex-col items-center">
          <div className="text-center w-full m-10">
            <div className="">
              <div>
                <TypewriterText
                  text="Press Play on Your Ideas!"
                  onComplete={() => setShowSecondLine(true)}
                  className="text-5xl font-bold text-blue-100"
                />
              </div>
              {/*showSecondLine && (
                <div className="max-w-4xl text-center mx-auto">
                  <TypewriterText text="" className="text-3xl" />
                </div>
              )*/}
            </div>
          </div>
          <div className="w-2/5 flex justify-center mt-10 mb-10 md:mt-10">
            <div className="text-center">
              {isLoggedIn ? (
                <Link
                  to="/get-started"
                  className="inline-block bg-black text-white px-6 py-3 rounded-full hover:text-black hover:bg-white text-lg font-bold"
                >
                  Get Started
                </Link>
              ) : (
                <>
                  <p className="mb-4">Let's Get Started</p>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <Link
                      to="/auth"
                      className="inline-block bg-black text-white px-6 py-3 rounded-full hover:text-black hover:bg-white text-lg font-bold"
                    >
                      Sign In
                    </Link>
                    <Link
                      to="/auth"
                      className="inline-block bg-black text-white px-6 py-3 rounded-full hover:text-black hover:bg-white text-lg font-bold"
                    >
                      Sign Up
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="w-full mb-20">
            <div className="flex flex-col md:flex-row items-center gap-8 mb-10">
              <div className="md:w-1/2 h-[200px] md:h-[400px]">
                <img
                  src={require("../assets/images/feature1.png")}
                  alt="Feature 1"
                  className="rounded-lg w-full h-full object-contain"
                />
              </div>
              <div className="md:w-1/2 space-y-4">
                <h2 className="text-3xl font-bold">
                  Transform your ideas into engaging audio content with just a
                  click.
                </h2>
                <p className="text-lg">
                  Let AI handle the heavy lifting of research and storytelling.
                  Simply input your topic and skip the hours of research - we'll
                  turn your curiosity into compelling audio content in minutes.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center gap-8">
              <div className="md:w-1/2 h-[400px] md:h-[500px]">
                <img
                  src={require("../assets/images/feature2.png")}
                  alt="Feature 2"
                  className="rounded-lg w-full h-full object-contain"
                />
              </div>
              <div className="md:w-1/2 space-y-4">
                <h2 className="text-3xl font-bold">
                  From curiosity to content in couple of minutes.
                </h2>
                <p className="text-lg">
                  Our AI transforms your topic into a well-structured podcast,
                  complete with detailed summaries and verified references -
                  making complex information accessible and engaging.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full mb-20 mt-10">
            <h2 className="text-5xl font-bold text-center mb-12">Questions</h2>
            <div className="space-y-4 max-w-3xl mx-auto">
              {[
                {
                  question: "What is Podfast, and how does it work?",
                  answer:
                    "Podfast is an AI-powered app that instantly generates personalised podcasts on any topic you choose. Simply enter a topic, and Podfast searches multiple verified sources to create a high-quality, tailored audio summary just for you.",
                },
                {
                  question: "Why should I use Podfast?",
                  answer:
                    "Podfast saves you time by researching on your behalf and delivering personalized, up-to-date information in audio format, allowing you to learn and stay informed while multitasking or on the go.",
                },
                {
                  question:
                    "How does Podfast ensure the information is accurate and up-to-date?",
                  answer:
                    "Podfast uses advanced AI to search multiple verified sources in real-time and incorporates real-time fact-checking to provide accurate and current information.",
                },
                {
                  question: "What topics can I generate podcasts on?",
                  answer:
                    "You can generate podcasts on virtually any topic—news, education, technology, hobbies, and more. If you can think of it, Podfast can create it.",
                },
                {
                  question: "I can’t find a podcast I generated earlier. Help?",
                  answer:
                    "If you’ve lost a podcast, check the 'Library' section in the app. If it’s not there, please contact our support team for assistance or jump back to 'Get Started' page to create again.",
                },
                {
                  question: "How can I provide feedback or report bugs?",
                  answer: (
                    <>
                      We'd love to hear from you! You can provide feedback or
                      report bugs directly within the app under the "Feedback"
                      section or email us at{" "}
                      <a
                        href="mailto:support@podfast.io"
                        className="underline hover:text-blue-300"
                      >
                        support@podfast.io
                      </a>
                      .
                    </>
                  ),
                },
                {
                  question:
                    "Can I choose the voice or language of the podcast?",
                  answer:
                    "Currently, Podfast offers a default voice in English, but we’re working on adding more voice options and language support in future updates.",
                },
              ].map((item, index) => (
                <div key={index} className="border-b border-white/20 pb-4">
                  <button
                    onClick={() =>
                      setExpandedQuestion(
                        expandedQuestion === index ? null : index
                      )
                    }
                    className="w-full flex justify-between items-center text-left text-2xl font-medium"
                  >
                    <span>{item.question}</span>
                    <svg
                      className={`w-6 h-6 transform transition-transform duration-300 ease-in-out ${
                        expandedQuestion === index ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {expandedQuestion === index && (
                    <div className="mt-4 text-lg text-gray-200 overflow-hidden transition-all duration-300 ease-in-out transform origin-top">
                      <div className="animate-[fadeIn_0.3s_ease-out]">
                        {item.answer}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer page="home" />
    </div>
  );
};

export default HomePage;
