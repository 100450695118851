import { useEffect } from "react";

const INACTIVE_TIMEOUT = 15 * 60 * 1000; // 1 minute in milliseconds

const AutoLogout = () => {
  useEffect(() => {
    let inactivityTimer;

    const logoutUser = () => {
      localStorage.clear();
      window.location.href = "/auth";
    };

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logoutUser, INACTIVE_TIMEOUT);
    };

    // Add event listeners
    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];
    events.forEach((event) => {
      document.addEventListener(event, resetTimer);
    });

    // Initial setup
    resetTimer();

    // Cleanup function
    return () => {
      clearTimeout(inactivityTimer);
      events.forEach((event) => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, []); // Empty dependency array means this runs once on mount

  return null; // This component doesn't render anything
};

export default AutoLogout;
