import React, { useState, useEffect } from "react";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./AudioPlayer.css";
import { useNavigate } from "react-router-dom";
import { useLibrary } from "../context/LibraryContext";

// Helper functions
const GENERATION_STATE_KEY = "podcastGenerationState";

const saveGenerationState = (state, userId) => {
  const stateToSave = {
    ...state,
    displayTopic: state.displayTopic,
    result: state.result,
    audioUrl: state.audioUrl,
    status: state.status,
  };
  localStorage.setItem(
    `${GENERATION_STATE_KEY}_${userId}`,
    JSON.stringify(stateToSave),
  );
};

const loadGenerationState = (userId) => {
  const saved = localStorage.getItem(`${GENERATION_STATE_KEY}_${userId}`);
  return saved ? JSON.parse(saved) : null;
};

const Alert = ({ children, variant = "error" }) => {
  const bgColor = variant === "error" ? "bg-red-100" : "bg-blue-100";
  const textColor = variant === "error" ? "text-red-800" : "text-blue-800";

  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-lg mb-4`}>
      {children}
    </div>
  );
};

const CustomAudioPlayer = ({ src, onError }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [audioBlob, setAudioBlob] = useState(null);

  // Format audio URL and add authentication
  const fetchAudio = async (url) => {
    try {
      const token = localStorage.getItem("token");
      if (!url || !token) {
        throw new Error("Missing URL or authentication token");
      }

      const fullUrl = url.startsWith("http")
        ? url
        : `${process.env.REACT_APP_API_URL || ""}${url}`;

      const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      setAudioBlob(audioUrl);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching audio:", error);
      onError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (src) {
      setIsLoading(true);
      fetchAudio(src);
    }

    // Cleanup
    return () => {
      if (audioBlob) {
        URL.revokeObjectURL(audioBlob);
      }
    };
  }, [src]);

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  return (
    <div className="audio-player-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">Loading audio...</div>
        </div>
      )}
      {audioBlob && (
        <AudioPlayer
          src={audioBlob}
          onLoadedData={handleLoadedData}
          onError={(e) => {
            console.error("Audio playback error:", {
              error: e,
              originalSrc: src,
            });
            setIsLoading(false);
            onError(e);
          }}
          autoPlay={false}
          showJumpControls={true}
          showSkipControls={false}
          showFilledVolume={true}
          customProgressBarSection={[
            "CURRENT_TIME",
            "PROGRESS_BAR",
            "DURATION",
          ]}
          customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
          className={`rounded-lg custom-player ${isLoading ? "loading" : ""}`}
          style={{
            borderRadius: "0.5rem",
            backgroundColor: "white",
            boxShadow: "none",
          }}
          customStyles={{
            progressBar: {
              backgroundColor: "#E5E7EB",
            },
            progressBarHeight: 2,
            trackArtistColor: "#4B5563",
            currentTimeColor: "#4B5563",
            durationColor: "#4B5563",
          }}
        />
      )}
    </div>
  );
};

// Add CSS for loading overlay
const styles = `
.audio-player-container {
  position: relative;
  width: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  border-radius: 0.5rem;
}

.loading-spinner {
  color: #4B5563;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.loading-spinner::before {
  content: '';
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border: 2px solid #E5E7EB;
  border-top-color: #4B5563;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.custom-player.loading {
  opacity: 0.7;
}
`;

// Add style tag to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const GeneratePodcast = ({ onPodcastGenerated }) => {
  const [topic, setTopic] = useState("");
  const [displayTopic, setDisplayTopic] = useState("");
  const [category, setCategory] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [status, setStatus] = useState("initial");
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [podCastError, setPodCastError] = useState("");
  const [activeTab, setActiveTab] = useState("transcript");
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const { libraryItems, isLoading, fetchLibraryItems } = useLibrary();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/auth");
    } else {
      // Decode the token to get the user ID
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      console.log("decodedToken", decodedToken);
      setUserId(decodedToken.user_id);

      // Load user-specific state
      const savedState = loadGenerationState(decodedToken.user_id);
      if (savedState) {
        setStatus(savedState.status);
        setProgress(savedState.progress || 0);
        setCurrentStep(savedState.currentStep || "");
        setSessionId(savedState.sessionId);
        setDisplayTopic(savedState.displayTopic);
        setResult(savedState.result);
        setAudioUrl(savedState.audioUrl);

        if (savedState.status === "processing") {
          pollProgress(savedState.sessionId);
        }
      } else {
        // Fetch the last generated podcast for this user
        if (decodedToken.user_id) {
          fetchLastGeneratedPodcast(decodedToken.user_id);
        }
      }
    }
  }, [navigate]);

  const fetchLastGeneratedPodcast = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `/api/last-generated-podcast/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const lastPodcast = response.data;
      if (lastPodcast) {
        setStatus("completed");
        setResult(lastPodcast);
        setDisplayTopic(lastPodcast.topic);
        if (lastPodcast.audio_output?.url) {
          setAudioUrl(`{lastPodcast.audio_output.url}`);
        }
      }
    } catch (error) {
      console.error("Error fetching last generated podcast:", error);
    }
  };

  // Handle topic input with character limit
  const handleTopicChange = (e) => {
    const newTopic = e.target.value;
    setTopic(newTopic);
    // Update display topic with truncated version if necessary
    setDisplayTopic(
      newTopic.length > 50 ? `${newTopic.substring(0, 47)}...` : newTopic,
    );
  };

  // Update pollProgress to use user-specific storage
  const pollProgress = async (sid) => {
    try {
      const response = await fetch(`/api/progress/${sid}`);
      const data = await response.json();

      if (data.status === "completed") {
        console.log("Podcast generation completed:", data);
        fetchLibraryItems();
        setStatus("completed");
        setResult(data.result);

        if (data.result?.error) {
          setPodCastError(data.result?.error);
        }

        // Process audio URL
        if (data.result?.audio_output?.url) {
          // Remove template literals from the URL
          const processedUrl = data.result.audio_output.url.replace(
            /[{}]/g,
            "",
          );
          setAudioUrl(processedUrl);

          saveGenerationState(
            {
              status: "completed",
              result: data.result,
              audioUrl: processedUrl,
              displayTopic,
            },
            userId,
          );

          sessionStorage.setItem("lastDisplayTopic", displayTopic);

          // Ensure the library is updated
          if (onPodcastGenerated) {
            setTimeout(() => {
              onPodcastGenerated();
            }, 1000);
          }
        }
      } else if (data.status === "failed") {
        setStatus("failed");
        setError(data.error);
        localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
      } else {
        setProgress(data.progress);
        setCurrentStep(data.current_step);
        saveGenerationState(
          {
            status: "processing",
            progress: data.progress,
            currentStep: data.current_step,
            sessionId: sid,
            displayTopic,
          },
          userId,
        );
        setTimeout(() => pollProgress(sid), 1000); // Add delay between polls
      }
    } catch (err) {
      console.error("Poll progress error:", err);
      setError(err.message);
      setStatus("failed");
      localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
    }
  };

  // Update handleSubmit to use user-specific storage
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setStatus("processing");

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/generate-podcast",
        {
          query: topic,
          category,
          timeframe: category === "news" ? timeframe : null,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const sid = response.data.session_id;
      setSessionId(sid);

      saveGenerationState(
        {
          status: "processing",
          progress: 0,
          currentStep: "",
          sessionId: sid,
          displayTopic,
        },
        userId,
      );

      pollProgress(sid);
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      if (error.response?.status === 429) {
        setError(
          "You have reached the limit of 5 podcast generations per day. Please try again tomorrow.",
        );
      } else {
        setError(
          error.response?.data?.detail ||
            "An error occurred while initiating the podcast.",
        );
      }
      setStatus("initial");
      localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
    }
  };

  const handleAudioError = (error) => {
    console.error("Audio playback error:", error);
    setError("Error playing audio. Please try again.");
  };

  // Update handleNewPodcast to clear user-specific storage
  const handleNewPodcast = () => {
    localStorage.removeItem(`${GENERATION_STATE_KEY}_${userId}`);
    setTopic("");
    setDisplayTopic("");
    setCategory("");
    setTimeframe("");
    setSessionId(null);
    setStatus("initial");
    setProgress(0);
    setCurrentStep("");
    setAudioUrl(null);
    setResult(null);
    setError(null);
    setActiveTab("transcript");
  };

  const renderReferences = (references) => {
    if (!references || references === "") {
      return (
        <p className="text-gray-500 italic h-12 flex items-center justify-center">
          {podCastError ? podCastError : "No references available."}
        </p>
      );
    }

    // Split references into lines
    const lines = references.split("\n").filter((line) => line.trim());

    // Process each line to format citations and URLs
    const processedReferences = lines.map((line, index) => {
      // Match citation patterns like [medtechdive1], [venturebeat3], etc.
      const citationRegex = /\[(.*?)\]/g;

      // Match URLs
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Replace citations with bold text
      let processedLine = line.replace(citationRegex, (match, citation) => {
        return `<strong>${match}</strong>`;
      });

      // Replace URLs with clickable links
      processedLine = processedLine.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:underline break-all">${url}</a>`;
      });

      // Add reference number and process HTML
      return (
        <div key={index} className="mb-3 last:mb-0 pl-8 relative">
          <span className="absolute left-0 font-bold">{index + 1}.</span>
          <div
            dangerouslySetInnerHTML={{
              __html: processedLine,
            }}
            className="reference-content"
          />
        </div>
      );
    });

    return <div className="space-y-1">{processedReferences}</div>;
  };

  const getDisplayStepName = (step) => {
    // Map technical step names to user-friendly names
    const stepNameMap = {
      prompt_engineer: "Understanding input",
      search_execution: "Searching",
      reflection: "Thinking and analyzing",
      summarization: "Gathering information",
      summary_reflection: "Thinking and organizing",
      generate_podcast_script: "Putting Together",
      generate_audio: "Generating Podcast",
    };

    // Return mapped name or original if not found
    return stepNameMap[step] || step;
  };

  // Add this new component for title display
  const TopicDisplay = ({ topic }) => (
    <div className="w-full bg-gray-100 p-4 mb-4 rounded-lg">
      <h3 className="text-lg font-medium text-gray-900 truncate">{topic}</h3>
    </div>
  );

  const renderContent = () => {
    switch (status) {
      case "initial":
        return (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="bg-gray-100 rounded-lg p-4">
              <textarea
                className="w-full bg-transparent resize-none outline-none"
                rows="4"
                placeholder="Better inputs will lead to better podcasts."
                value={topic}
                onChange={handleTopicChange}
                maxLength={500}
              />
              <div className="text-right text-sm text-gray-500">
                {500 - topic.length} characters remaining
                {topic.length > 50 && (
                  <div className="text-yellow-600">
                    Note: Title will be truncated to 50 characters
                  </div>
                )}
              </div>
            </div>

            <div className="relative">
              <select
                className="w-full p-2 border rounded-md"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select a category</option>
                <option value="general">General</option>
                <option value="news">News</option>
              </select>
            </div>

            {category === "news" && (
              <div className="relative">
                <select
                  className="w-full p-2 border rounded-md"
                  value={timeframe}
                  onChange={(e) => setTimeframe(e.target.value)}
                >
                  <option value="">Select a timeframe</option>
                  <option value="last day">Last Day</option>
                  <option value="last week">Last Week</option>
                  <option value="last month">Last Month</option>
                </select>
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-black text-white py-3 rounded-full hover:bg-gray-800 transition-colors duration-200"
              disabled={
                !topic || !category || (category === "news" && !timeframe)
              }
            >
              Generate Podcast
            </button>
          </form>
        );

      case "processing":
        return (
          <div className="space-y-4">
            <TopicDisplay topic={displayTopic} />

            <div className="w-full bg-gray-200 rounded-full h-4">
              <div
                className="bg-[#3331C5] h-4 rounded-full transition-all duration-500"
                style={{ width: `${progress * 100}%` }}
              ></div>
            </div>
            <p className="text-center text-gray-700">
              {currentStep ? getDisplayStepName(currentStep) : "Processing..."}
            </p>
          </div>
        );

      case "completed":
        return (
          <div className="space-y-4">
            <TopicDisplay topic={displayTopic} />

            {audioUrl && (
              <CustomAudioPlayer src={audioUrl} onError={handleAudioError} />
            )}

            <div className="flex space-x-2">
              <button
                onClick={() => setActiveTab("transcript")}
                className={`flex-1 py-2 px-4 text-center font-semibold transition-colors duration-200 ${
                  activeTab === "transcript"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200 text-black hover:bg-gray-300"
                }`}
              >
                Summary
              </button>
              <button
                onClick={() => setActiveTab("references")}
                className={`flex-1 py-2 px-4 text-center font-semibold transition-colors duration-200 ${
                  activeTab === "references"
                    ? "bg-[#3331C5] text-white"
                    : "bg-gray-200 text-black hover:bg-gray-300"
                }`}
              >
                References
              </button>
            </div>

            <div className="bg-gray-100 rounded-lg max-h-96 overflow-y-auto">
              {activeTab === "transcript" ? (
                <div className="p-2">
                  {result?.content?.summary ? (
                    result.content.summary.split("\n").map((paragraph, idx) => (
                      <p
                        key={idx}
                        className="whitespace-pre-wrap text-sm bg-white p-2 border-b last:border-b-0"
                      >
                        {paragraph}
                      </p>
                    ))
                  ) : (
                    <p className="text-gray-500 italic h-12 flex items-center justify-center">
                      {podCastError ? podCastError : "No summary available."}
                    </p>
                  )}
                </div>
              ) : (
                <div className="p-2">
                  {renderReferences(result?.content?.references)}
                </div>
              )}
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleNewPodcast}
                className="bg-[#3331C5] text-white px-6 py-3 rounded-full hover:bg-[#3331C5] transition-colors duration-200"
              >
                Generate Another Podcast
              </button>
            </div>
          </div>
        );

      case "failed":
        return (
          <div className="space-y-4">
            <TopicDisplay topic={displayTopic} />

            <Alert variant="error">
              {error || "An error occurred while generating the podcast."}
            </Alert>
            <button
              onClick={handleNewPodcast}
              className="w-full bg-blue-600 text-white py-3 rounded-full hover:bg-blue-700 transition-colors duration-200"
            >
              Try Again
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto">
      {error && status !== "failed" && <Alert variant="error">{error}</Alert>}
      {renderContent()}
    </div>
  );
};

export default GeneratePodcast;
