import React, { useState, useEffect, createContext, useContext } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";

export const LibraryContext = createContext(null);

export const LibraryProvider = ({ children }) => {
  const [libraryItems, setLibraryItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      setLibraryItems([]);
      setLastFetchTime(null);
      setIsLoading(false);
    }
  }, [isLoggedIn]); // Changed dependency to isLoggedIn

  const fetchLibraryItems = async (force = false) => {
    /*if (!force && lastFetchTime && Date.now() - lastFetchTime < 60000) {
      return;
    }*/

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/library`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      console.log("response.data--lib", response.data);
      setLibraryItems(response.data);
      setLastFetchTime(Date.now());
    } catch (error) {
      console.error("Error fetching library items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LibraryContext.Provider
      value={{ libraryItems, isLoading, fetchLibraryItems }}
    >
      {children}
    </LibraryContext.Provider>
  );
};
export const useLibrary = () => {
  const context = useContext(LibraryContext);
  if (context === undefined) {
    throw new Error("useLibrary must be used within a LibraryProvider");
  }
  return context;
};
